import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../../components/layout"
import { Container } from "@material-ui/core"
import { Typography } from "@material-ui/core";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { makeStyles } from "@material-ui/core/styles";
//Models
import { PromotionalTermModel } from "../../components/utils/model/PromotionalTermModel";
//Assets
import OTONIO24 from "./../../assets/Benefits/OTONIO_24.png";


const useStyles = makeStyles(theme => ({
    root: {},
    ol: { paddingInlineStart: 0 },
    containerPart: {
        [theme.breakpoints.down("md")]: {
            marginTop: "10em",
        },
        [theme.breakpoints.down("sm")]: {
            marginTop: "7em",
        },
        marginTop: "12em",
    },
    imgSize: {
        [theme.breakpoints.down("xs")]: {
            width: "60%",
        },
        [theme.breakpoints.down("sm")]: {
            width: "70%",
        },
        [theme.breakpoints.down("md")]: {
            width: "80%",
        },
        [theme.breakpoints.down("lg")]: {
            width: "90%",
        },
        [theme.breakpoints.down("xl")]: {
            width: "100%",
        },
        width: "100%",
    },
    boxImg: {
        display: "flex",
        justifyContent: "center",
        margin: "auto",
    },
    liStyle: {
        textAlign: "justify",
        padding: 3,
    },
    scrollableDiv: {
        [theme.breakpoints.down("xs")]: {
            height: "auto", /* Alto del div */
        },
        [theme.breakpoints.down("sm")]: {
            height: "auto", /* Alto del div */
        },
        [theme.breakpoints.down("md")]: {
            height: "auto", /* Alto del div */
        },
        [theme.breakpoints.down("lg")]: {
            height: "700px", /* Alto del div */
        },
        [theme.breakpoints.down("xl")]: {
            height: "800px", /* Alto del div */
        },
        height: "auto", /* Alto del div */
        width: "auto", /* Ancho del div */
        overflow: "auto", /* Habilita scroll cuando el contenido excede las dimensiones */
        '&::-webkit-scrollbar': {
            width: '10px', // Ancho del scrollbar
        },
        '&::-webkit-scrollbar-track': {
            background: '#f1f1f1', // Color del track
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#888', // Color del scrollbar
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: '#555', // Color del scrollbar al hover
        },
    }
}));

export default function Benefits() {
    let propertiesLayout = new PromotionalTermModel("Otoño24", OTONIO24, "OTOÑO24");
    const classes = useStyles()
    return (
        <Layout>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{propertiesLayout.name.toUpperCase()} | Terminos & Condiciones</title>
            </Helmet>
            <Container className={classes.containerPart}>
                <Box mt={4} py={2}>
                    <Typography variant="h2" component="h2" gutterBottom align="center">
                        <b>Términos y Condiciones de la Campaña Promocional</b>
                    </Typography>
                    <Typography variant="h2" component="h2" gutterBottom align="center">
                        <b>“{propertiesLayout.name.toUpperCase()}”</b>
                    </Typography>
                </Box>

                <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 6, sm: 6, md: 12 }} sx={{
                    maxHeight: {
                        xs: "auto", // Pantallas pequeñas
                        sm: "auto", // Pantallas medianas
                        md: "700px", // Pantallas grandes
                        lg: "800px", // Pantallas más grandes
                    }, marginBottom: 10
                }}>
                    <Grid item xs={6} sm={6} md={6} sx={{ flexGrow: 1 }}>
                        {" "}
                        <img
                            src={propertiesLayout.linkImage}
                            alt={propertiesLayout.desc}
                            className={classes.imgSize}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} sx={{ flexGrow: 1 }}>
                        <div className={classes.scrollableDiv}>
                            <Typography variant="h4" gutterBottom inline>
                                <b>1. Introducción</b>
                            </Typography>
                            <Typography
                                variant="body1"
                                gutterBottom
                                inline
                                className={classes.liStyle}
                            >
                                La campaña “OTOÑO24” estará vigente desde el 1 de noviembre de 2024 hasta el
                                31 de diciembre de 2024.
                            </Typography>
                            <Typography variant="h4" gutterBottom inline>
                                <b>2. Requisitos para participar</b>
                            </Typography>
                            <Typography
                                variant="body1"
                                gutterBottom
                                inline
                                className={classes.liStyle}
                            >
                                Para acceder al beneficio de esta campaña, los clientes deben cumplir con los
                                siguientes requisitos:
                            </Typography>
                            <Typography variant="body1" gutterBottom inline>
                                <ul>
                                    <li className={classes.liStyle}>
                                        <b>Clientes elegibles:</b> Clientes activos o con cuentas de reciente apertura.
                                    </li>
                                    <li className={classes.liStyle}>
                                        <b>Incremento de capital:</b> Realizar un incremento de capital igual o superior a
                                        $250,000 MXN en el producto de inversión “Dinámico” durante el periodo de
                                        vigencia de la campaña.
                                    </li>
                                </ul>
                            </Typography>
                            <Typography variant="h4" gutterBottom inline>
                                <b>3. Incentivo</b>
                            </Typography>
                            <Typography
                                variant="body1"
                                gutterBottom
                                inline
                                className={classes.liStyle}
                            >
                                Los clientes que cumplan con los requisitos de la campaña podrán obtener los
                                siguientes beneficios:
                            </Typography>
                            <Typography variant="body1" gutterBottom inline>
                                <ul>
                                    <li className={classes.liStyle}>
                                        <b>Tasa de rendimiento:</b> Tasa anualizada mínima del 10%, sin embargo, el
                                        cliente podrá alcanzar una tasa anualizada máxima del 13%.
                                    </li>
                                    <li className={classes.liStyle}>
                                        <b>Incremento progresivo de la tasa:</b> La tasa de rendimiento se ajustará
                                        progresivamente según el monto del capital invertido de manera constante,
                                        permitiendo maximizar el rendimiento hasta un límite del 13% anual.
                                    </li>
                                    <li className={classes.liStyle}>
                                        <b>Ventana de liquidez:</b> El cliente podrá tener acceso a sus recursos de
                                        manera trimestral.
                                    </li>
                                </ul>
                            </Typography>
                            <Typography variant="h4" gutterBottom inline>
                                <b>4. Condiciones para obtener los distintos niveles dentro de la campaña:</b>
                            </Typography>
                            <Typography
                                variant="body1"
                                gutterBottom
                                inline
                                className={classes.liStyle}
                            >
                                Para acceder al Nivel 1 de la campaña, el cliente debe:
                            </Typography>
                            <Typography variant="body1" gutterBottom inline>
                                <ol>
                                    <li className={classes.liStyle}>
                                        <b>Clientes con inversión activa:</b> <br />Realizar un incremento de capital por un monto mínimo de $250,000 MXN
                                        en el producto “Dinámico” que tenga activo actualmente.
                                    </li>
                                    <li className={classes.liStyle}>
                                        <b>Clientes sin inversión activa:</b> <br />Realizar la contratación del producto “Dinámico” por un monto mínimo de
                                        $255,000 MXN, desglosado como sigue:
                                        <Typography variant="body1" gutterBottom inline>
                                            <ul>
                                                <li className={classes.liStyle}>
                                                    <b>$5,000 MXN:</b> Importe mínimo requerido para a la contratación del
                                                    producto con una tasa anual del 10% (Tasa base del producto).
                                                </li>
                                                <li className={classes.liStyle}>
                                                    <b>$250,000 MXN:</b> Incremento de capital requerido en esta campaña,
                                                    mismo que otorga un 0.50% adicional sobre la tasa base,
                                                    alcanzando una tasa final del 10.50%.
                                                </li>
                                            </ul>
                                        </Typography>
                                    </li>
                                    <li className={classes.liStyle}>
                                        <b>Incremento de saldo en la inversión:</b> <br />Por cada incremento adicional de
                                        $250,000 MXN en el capital invertido en el producto “Dinámico”, la tasa de
                                        interés aumentará un 0.50%, hasta alcanzar un máximo de 13% anual.
                                    </li>
                                </ol>
                            </Typography>
                            <Typography
                                variant="body1"
                                gutterBottom
                                inline
                                className={classes.liStyle}
                            >
                                El cliente podrá escalar en los distintos niveles de la campaña con base a los
                                incrementos progresivos de capital que vaya realizando por la cantidad de
                                $250,000 MXN o podrá acceder desde un inicio al nivel que desee realizando el
                                depósito de capital equivalente al nivel que desee alcanzar, siempre considerando
                                el importe mínimo de contratación de dicho producto ($5,000 MXN)
                            </Typography>
                            <Typography variant="h4" gutterBottom inline>
                                <b>5. Procedimiento para Acceder a la Campaña</b>
                            </Typography>
                            <Typography
                                variant="body1"
                                gutterBottom
                                inline
                                className={classes.liStyle}
                            >
                                Para beneficiarse de esta campaña, los clientes deben seguir estos pasos:
                            </Typography>
                            <Typography variant="body1" gutterBottom inline>
                                <ul>
                                    <li className={classes.liStyle}>
                                        <b>Paso 1:</b> Transferir fondos por un importe igual o superior a $5,000 MXN a
                                        su Cuenta Cero CFL.
                                    </li>
                                    <li className={classes.liStyle}>
                                        <b>Paso 2:</b> Realizar la contratación del producto “Dinámico” por una importe
                                        igual o superior a $5,000 MXN.
                                    </li>
                                    <li className={classes.liStyle}>
                                        <b>Paso 3:</b> Realizar el incremento de capital del producto “Dinámico”
                                        contratado por un importe igual o superior a $250,000 MXN.
                                    </li>
                                    <li className={classes.liStyle}>
                                        <b>Paso 4:</b> El cliente deberá solicitar a su Asesor Financiero la aplicación del
                                        beneficio de la presente campaña.
                                    </li>
                                </ul>
                            </Typography>
                            <Typography
                                variant="body1"
                                gutterBottom
                                inline
                                className={classes.liStyle}
                            >
                                Si el cliente ya cuenta con una inversión activa en el producto “Dinámico”,
                                únicamente deberá realiza el incremento de capital de al menos $250,000 MXN.
                            </Typography>
                            <Typography variant="h4" gutterBottom inline>
                                <b>6. Condiciones Generales</b>
                            </Typography>
                            <Typography variant="body1" gutterBottom inline>
                                <ol>
                                    <li className={classes.liStyle}>
                                        <b>Producto aplicable: </b>Esta promoción se aplica únicamente para el producto <b>“Dinámico”</b>.
                                    </li>
                                    <li className={classes.liStyle}>
                                        <b>Capital nuevo:</b> El capital adicional debe ser nuevo y transferido entre el 1
                                        de noviembre de 2024 y el 31 de diciembre de 2024.
                                    </li>
                                    <li className={classes.liStyle}>
                                        <b>Sin excepciones:</b> No se permitirán contrataciones fuera del plazo de
                                        vigencia.
                                    </li>
                                    <li className={classes.liStyle}>
                                        <b>Tasa sujeta a cambios:</b> La tasa de rendimiento promocional está sujeta a
                                        modificaciones sin previo aviso, según condiciones de mercado.
                                    </li>
                                    <li className={classes.liStyle}>
                                        <b>Niveles de la Campaña:</b> La campaña consta de 6 niveles, a los cuales
                                        podrá acceder el cliente por cada incremento de capital que realice por una
                                        cantidad de $250,000 MXN.
                                    </li>
                                    <li className={classes.liStyle}>
                                        <b>Incremento de saldo posterior a la contratación del producto Dinámico:</b><br />Para incrementar el saldo de su inversión en el producto “Dinámico”, el
                                        cliente deberá:
                                        <Typography variant="body1" gutterBottom inline>
                                            <ul>
                                                <li className={classes.liStyle}>
                                                    Solicitar la cancelación anticipada del producto a su Asesor
                                                    Financiero, la cual no generara ninguna penalización.
                                                </li>
                                                <li className={classes.liStyle}>
                                                    Realizar la transferencia de capital a incrementar a su cuenta CFL
                                                    Cero CFL.
                                                </li>
                                                <li className={classes.liStyle}>
                                                    Realizar nuevamente la contratación del producto “Dinámico”.
                                                </li>
                                                <li className={classes.liStyle}>
                                                    Solicitar a su Asesor Financiero la aplicación del beneficio de esta
                                                    campaña.
                                                </li>
                                            </ul>
                                        </Typography>
                                    </li>
                                    <li className={classes.liStyle}>
                                        <b>Liquidez Trimestral:</b><br />Al tratarse de una inversión a plazo fijo, el cliente tendrá el beneficio de
                                        poder acceder a sus recursos cada 90 días, sin embargo, para realizarlo
                                        contará con un lapso de 7 días naturales posteriores una vez que se
                                        cumpla cada ciclo de 90 días.
                                    </li>
                                    <li className={classes.liStyle}>
                                        <b>Exclusividad de la Campaña:</b><br />Los fondos ingresados ​​bajo esta promoción
                                        solo se aplican a los términos de esta campaña. Si el cliente desea
                                        participar en otra campaña, deberá cumplir con los términos específicos de
                                        esa campaña adicional.
                                    </li>
                                    <li className={classes.liStyle}>
                                        <b>Vigencia de la Tasa:</b><br />La tasa de interés promocional tendrá una duración
                                        de 1 año a partir de la fecha de contratación de esta campaña. Al término
                                        de la misma el producto regresara a su tasa base (10% anualizado)
                                    </li>
                                    <li className={classes.liStyle}>
                                        <b>Plazo de aplicación del beneficio:</b><br />El beneficio se reflejará en el producto
                                        “Dinámico” en un plazo máximo de 72 horas. Los clientes podrán confirmar
                                        el incremento en su tasa de interés a través de su aplicación móvil o sitio
                                        web.
                                    </li>
                                    <li className={classes.liStyle}>
                                        <b>Aceptación de los Términos y Condiciones:</b><br />Al participar en esta
                                        campaña, los clientes aceptan y se comprometen a cumplir con todos los
                                        términos y condiciones aquí establecidos.
                                    </li>

                                </ol>
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </Layout >
    )
}
